<template>
  <div class="reset-password">
    <b-container>
      <b-row class="login-holder text-center" align-v="center">
        <b-col>
          <transition name="slide-fade">
            <b-container class="login-box shadow" v-show="show">
              <div class="wrapper">
                <div class="image">
                  <img src="../assets/circular.svg" alt="circular" />
                </div>
                <div class="text-content">
                  <img src="../assets/eos.png" alt="circular" />

                  <h1 class="text-uppercase">
                    {{ $t("reset-password.reset-password") }}
                  </h1>
                </div>
              </div>
              <b-container class="mt-4" v-if="state == 0">
                <p>{{ $t("reset-password.instructions") }}</p>
                <b-form
                  @submit.prevent="onSubmit"
                  id="reset-password-form"
                  class="mb-3"
                  v-if="!showAlert"
                >
                  <b-form-group
                    :description="$t('reset-password.usernameDescription')"
                  >
                    <b-form-input
                      id="username"
                      class="user-input"
                      v-model="form.username"
                      type="text"
                      :placeholder="$t('login.username')"
                      size="lg"
                      trim
                      required
                    ></b-form-input>
                  </b-form-group>

                  <b-button
                    type="submit"
                    class="login-button"
                    size="lg"
                    variant="primary"
                    >{{ $t("reset-password.reset-password") }}</b-button
                  >
                </b-form>
                <b-alert :show="showAlert" variant="info">
                  <p>
                    {{ message }}
                  </p>
                </b-alert>
                <router-link :to="{ name: 'Login' }">{{
                  $t("reset-password.backToLogin")
                }}</router-link>
              </b-container>

              <b-container class="mt-4" v-if="state == 1">
                <p>{{ $t("reset-password.instructionsPassword") }}</p>
                <b-form
                v-if="!showAlert"
                  @submit.prevent="onSubmitPassword"
                  id="new-password-form"
                  class="mb-3"
                >
                  <b-form-group>
                    <b-form-input
                      id="password1"
                      class="user-input"
                      v-model="newPasswordForm.password1"
                      type="password"
                      :placeholder="$t('reset-password.password')"
                      size="lg"
                      required
                      autocomplete="off"
                    ></b-form-input>
                  </b-form-group>
                  <b-form-group>
                    <b-form-input
                      id="password2"
                      class="user-input"
                      v-model="newPasswordForm.password2"
                      type="password"
                      :placeholder="$t('reset-password.verifyPassword')"
                      size="lg"
                      trim
                      required
                      :state="passwordState"
                      autocomplete="off"
                    ></b-form-input>
                    <b-form-invalid-feedback>
                      <span>{{$t('reset-password.passwordsNotMatching')}}</span>
                    </b-form-invalid-feedback>
                  </b-form-group>

                  <b-button
                    type="submit"
                    class="login-button"
                    size="lg"
                    variant="primary"
                    >{{ $t("reset-password.reset-password") }}</b-button
                  >
                </b-form>
                <b-alert :show="showAlert" variant="info">
                  <p>
                    {{ message }}
                  </p>
                </b-alert>
                <router-link :to="{ name: 'Login' }">{{
                  $t("reset-password.backToLogin")
                }}</router-link>
              </b-container>
            </b-container>
          </transition>
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>

<script>
import axios from "axios";
// @ is an alias to /src
import { mapActions } from "vuex";
const STATE_ENTER_USERNAME = 0;
const STATE_ENTER_PASSWORD = 1;
export default {
  name: "ResetPassword",
  data: () => {
    return {
      form: {},
      newPasswordForm: {
        password1: "",
        password2: "",
      },
      show: false,
      message: "",
      state: STATE_ENTER_USERNAME,
    };
  },
  computed: {
    showAlert() {
      return this.message != "";
    },
    passwordState() {
      if (
        this.newPasswordForm.password1 == "" ||
        this.newPasswordForm.password2 == ""
      ) {
        return null;
      }
      return this.newPasswordForm.password1 === this.newPasswordForm.password2;
    },
  },
  methods: {
    ...mapActions("alerts", ["fireAlert", "resetAlert"]),
    onSubmit() {
      axios
        .post("/ui-api/v1/reset-password", {
          username: this.form.username,
          lang: this.$i18n.locale,
        })
        .then(() => {
          this.message = this.$t("reset-password.emailSent");
        })
        .catch((err) => {
          console.error(err);
          if (err.response.status == 404) {
            this.fireAlert([
              this.$t("Error"),
              this.$t("reset-password.usernameNotFound"),
              "danger",
            ]);
          } else if (err.response.status == 406) {
            this.fireAlert([
              this.$t("Error"),
              this.$t("reset-password.noEmail"),
              "danger",
            ]);
          } else {
            console.error(err);
            this.fireAlert([
              this.$t("Error"),
              this.$t("reset-password.error"),
              "danger",
            ]);
          }
        });
    },
    onSubmitPassword() {
      axios
        .post("/ui-api/v1/set-password", {
          token: this.$route.query['token'],
          password: this.newPasswordForm.password1,
        })
        .then(() => {
          this.message = this.$t("reset-password.success");
        })
        .catch((err) => {
          console.error(err);
            console.error(err);
            this.fireAlert([
              this.$t("Error"),
              this.$t("reset-password.error"),
              "danger",
            ]);
        });
    },

    async checkToken(token) {
      let tokenChecker = new RegExp(
        /[a-f0-9]{8}-[a-f0-9]{4}-[a-f0-9]{4}-[a-f0-9]{4}-[a-f0-9]{12}/i
      );

      if (!tokenChecker.test(token)) {
        // reject request if token has no UUID format
        this.fireAlert([
          this.$t("Error"),
          this.$t("login.invalidToken"),
          "danger",
        ]);
        return false;
      }
      let check = false;
      await axios.post('/ui-api/v1/check-password-reset-token', {token})
      .then(() => {
        check = true;
      })
      .catch((err) => {
        console.error(err);
        this.fireAlert([
          this.$t("Error"),
          this.$t("reset-password.invalidToken"),
          "danger",
        ]);
      })
      return check;
    },
  },
  async mounted() {
    setTimeout(() => {
      this.show = true;
    }, 200);
    let token = this.$route.query["token"];
    if(!token) {console.log("NOT TOKEN"); return;}

    let check = await this.checkToken(token)
    if (check) {
      this.state = STATE_ENTER_PASSWORD;
    }
  },
};
</script>
<style lang="scss" scoped>
@import "../theme.scss";

.login-holder {
  height: calc(100vh - #{$navbar-height});
}
.reset-password::before {
  content: "";
  position: fixed;
  left: 0;
  right: 0;
  z-index: -1;

  display: block;
  background-image: url("~@/assets/photovoltaic-bg.jpg");
  width: 100%;
  height: 100%;

  -webkit-filter: blur(5px);
  -moz-filter: blur(5px);
  -o-filter: blur(5px);
  -ms-filter: blur(5px);
  filter: blur(5px);
}

.login-logo {
  max-height: 20vh;
}
.alert-max-width {
  max-width: 75vw;
}
.login-box {
  max-width: 75vw;
  background: $white;
  padding: 1rem;
  border-radius: 1rem;
}
.user-input {
  // font-size: 2rem;
  text-align: center;
  max-width: 75%;
  margin: auto;
}
@include media-breakpoint-up(sm) {
  .login-button {
    padding: 1rem 5rem;
  }
}

@include media-breakpoint-down(sm) {
  .user-input {
    max-width: 100%;
  }
}
.slide-fade-enter-active {
  transition: all 0.5s ease;
}
.slide-fade-leave-active {
  transition: all 0.8s cubic-bezier(1, 0.5, 0.8, 1);
}
.slide-fade-enter, .slide-fade-leave-to
/* .slide-fade-leave-active below version 2.1.8 */ {
  transform: translateY(-10px);
  opacity: 0;
}

.wrapper {
  display: flex;
  flex-wrap: wrap;
  .image {
    flex-grow: 1;
    display: flex;
    justify-content: center;
  }
  .text-content {
    margin-left: 1rem;
    flex-grow: 2;
    justify-content: flex-start;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-between;

    * {
      margin-bottom: 0.5rem;
    }

    img {
      height: 70px;
    }

    .tagline {
      font-family: "leadfont";
      text-transform: uppercase;
    }
  }
  @include media-breakpoint-down(sm) {
    .text-content {
      align-items: center;

      margin-top: 0.5rem;
    }
  }
}
</style>